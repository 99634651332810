import React from 'react'
import { BASE_URL } from '../assets/js/_variables';

const CTA = () => {
  return (
    <div className='fixed bottom-8 right-8 aspect-1'>
      <a href="https://api.whatsapp.com/send/?phone=+523221862244&text=Hola+Quiero+m%C3%A1s+informaci%C3%B3n&app_absent=0" target="_blank" rel="noopener noreferrer">
        <img className='w-20 h-20' src={`${BASE_URL}/cta.png`} alt="" />
      </a>
    </div>
  );
}
 
export default CTA;