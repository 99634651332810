import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faLocation,
  faMobile,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { BASE_URL, STATIC_DOCUMENTS } from "../assets/js/_variables"

const Footer = () => {
  const [lang, setLang] = useState()

  const txt = {
    en: {
      contactTitle: "Address & Contact",
      aboutTitle: "About us",
      aboutSite: "Sitemap",
      aboutTerms: "Terms & Conditions",
      aboutPrivacy: "Privacy Policy",
    },
    es: {
      contactTitle: "Dirección y Contacto",
      aboutTitle: "Acerca de nosotros",
      aboutSite: "Mapa del Sitio",
      aboutTerms: "Términos y condiciones",
      aboutPrivacy: "Política de Privacidad",
    },
  }

  const getLang = () => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("lang")
    }
  }

  useEffect(() => {
    if (getLang() === "es") {
      setLang("es")
    } else {
      setLang("en")
    }
  }, [])

  return (
    <footer className="bg-neutral-800 text-neutral-100">
      <div className="container mx-auto px-4 md:px-0 py-4 md:py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 py-4 md:py-8">
          <div>
            <h5 className="font-bold uppercase mb-4">
              {lang === "es" ? txt.es.contactTitle : txt.en.contactTitle}
            </h5>
            <ul>
              <li className="mb-2">
                <FontAwesomeIcon className="text-xl mr-2" icon={faEnvelope} />{" "}
                <a
                  className="hover:underline transition-all"
                  href="mailto:hbtours@hbprivileged.com"
                >
                  hbtours@hbprivileged.com
                </a>
              </li>
              <li className="mb-2">
                <FontAwesomeIcon className="text-xl mr-2" icon={faMobile} />{" "}
                <a
                  className="hover:underline transition-all"
                  href="tel:+523222267000"
                >
                  (+52) 322.226.7000 ext. 2017
                </a>{" "}
                / <br />{" "}
                <a
                  className="hover:underline transition-all"
                  href="https://api.whatsapp.com/send/?phone=+523221862244&text=Hola+Quiero+m%C3%A1s+informaci%C3%B3n&app_absent=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WhatsApp 322.186.22.44
                </a>
              </li>
              <li className="mb-2">
                <FontAwesomeIcon className="text-xl mr-2" icon={faLocation} />{" "}
                <a
                  className="hover:underline transition-all"
                  href="https://goo.gl/maps/mBurXDaEdpsuYiiv9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Av. México 1301, Colonia 5 de Diciembre Puerto Vallarta,
                  Jalisco. C.P. 48350 México
                </a>
              </li>
            </ul>
          </div>

          <div className="text-center">
            <img
              src={`${BASE_URL}/Logo-by-HB.png`}
              className="max-w-1/2"
              alt=""
            />

            <div className="mt-4">
              <FontAwesomeIcon className="text-4xl mr-2" icon={faFacebookF} />
              <FontAwesomeIcon className="text-4xl mr-2" icon={faInstagram} />
              <FontAwesomeIcon className="text-4xl mr-2" icon={faYoutube} />
              <a
                href="https://api.whatsapp.com/send/?phone=+523221862244&text=Hola+Quiero+m%C3%A1s+informaci%C3%B3n&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="text-4xl mr-2" icon={faWhatsapp} />
              </a>
            </div>
          </div>

          <div>
            <h5 className="font-bold uppercase mb-4">
              {lang === "es" ? txt.es.aboutTitle : txt.en.aboutTitle}
            </h5>
            <ul>
              {/* <li>
                <Link to="#" className="hover:underline transition-all">
                  {lang == "es" ? txt.es.aboutSite : txt.en.aboutSite}
                </Link>
              </li> */}
              <li>
                <a
                  href={`${STATIC_DOCUMENTS}/Terms-Conditions.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline transition-all"
                >
                  {lang == "es" ? txt.es.aboutTerms : txt.en.aboutTerms}
                </a>
              </li>
              <li>
                <a
                  href={`${STATIC_DOCUMENTS}/Privacy.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline transition-all"
                >
                  {lang == "es" ? txt.es.aboutPrivacy : txt.en.aboutPrivacy}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
