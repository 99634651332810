import React from 'react'

import Navbar   from './Navbar'
import Footer   from './Footer'
import CTA      from './CTA'

const Layout = ({ pageTitle, children }) => {
    return (
        <div className='scroll-smoth'>
            <title>{pageTitle}</title>
            <Navbar />
            <main className='mt-20 min-h-screen bg-white dark:bg-black scroll-smooth'>
                {children}
            </main>
            <Footer />
            <CTA />
        </div>
    );
}
 
export default Layout;